
import { StyleSheet, Text, View, Image, TextInput, Pressable, TouchableOpacity, Linking } from 'react-native';
import React, { useState, useRef } from 'react';
import Constants from 'expo-constants';
import { BrowserRouter, useParams, Routes, Route } from "react-router-dom";
import { getFirestore, doc, collection, limit, where, getDoc, deleteDoc, getDocs, query, setDoc, addDoc, updateDoc, serverTimestamp, increment } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useWindowDimensions } from 'react-native';
import emailjs from '@emailjs/browser';

import {
    useFonts,
    SourceSansPro_300Light,
    SourceSansPro_600SemiBold
} from '@expo-google-fonts/source-sans-pro';

import {
  PatrickHand_400Regular
} from '@expo-google-fonts/patrick-hand';

import { Button, InputField, } from './components';



// Initialize Firebase
const firebaseConfig = {
  apiKey: Constants.manifest.extra.apiKey,
  authDomain: Constants.manifest.extra.authDomain,
  projectId: Constants.manifest.extra.projectId,
  storageBucket: Constants.manifest.extra.storageBucket,
  messagingSenderId: Constants.manifest.extra.messagingSenderId,
  appId: Constants.manifest.extra.appId
};

let Firebase;

if (firebase.apps.length === 0) {
  Firebase = firebase.initializeApp(firebaseConfig);
  console.log("firebase :D");
}

let db = getFirestore();

export default function App() {

  const { height, width } = useWindowDimensions();
  let innerContainerStyle;


  useFonts({
      PatrickHand_400Regular,
      SourceSansPro_300Light,
      SourceSansPro_600SemiBold
  });
  if(width > (height / 0.65)){
    innerContainerStyle = {
      width: '58%',
      maxWidth: '58%'
    }
  }
  else {
    innerContainerStyle = {
      width: '100%',
      maxWidth: '100%'
    }    
  }

  return (
    <View style={styles.container}>
      <Image style={styles.backgroundimage} source={require('./assets/listenriceforestbg.svg')} />
      <View style={[{flex: 1, alignItems: 'center', justifyContent: 'flex-start'},innerContainerStyle]}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/getToken" element={<TokenPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </BrowserRouter>
      </View>
    </View>
  );
}



function MainPage() {

  const downloadRef = useRef(null);


  const handleGetTokenPress = () => {
    window.location.href = '/getToken'
  };

  const scrollToDownload = () => {
    downloadRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleAboutPressed = () => {
    window.location.href = '/about'
  }

  return(
      <View style={styles.boxcontainer}>


        <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 42, textAlign: 'center', width: '75%', margin: 10}}>{'WhatThat'}</Text>
        <View style={styles.headercontainer}>
          <Button 
              title={'About'}
              onPress={() => {
                handleAboutPressed();
              }}
              containerStyle={{width: '28%', minWidth: 60, marginTop: 0, marginBottom: 15}}
              titleSize={18}
            />
          <Button 
              title={'Download'}
              onPress={() => {
                scrollToDownload();
              }}
              containerStyle={{width: '28%', minWidth: 60, marginTop: 0, marginBottom: 15}}
              titleSize={18}
            />
          <Button 
              title={'Token'}
              onPress={() => {
                handleGetTokenPress();
              }}
              containerStyle={{width: '28%', minWidth: 60, marginTop: 0, marginBottom: 15}}
              titleSize={18}
            />

        </View>

        <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 24, textAlign: 'center', width: '80%'}}>
        {'Turn your computer into a language learning machine!\n'}
        </Text>

        <Text style={{fontFamily: 'SourceSansPro_300Light', fontSize: 18, textAlign: 'center', width: '80%'}}>
        {'\n'}
        </Text>
        <div style={{ position: 'relative', paddingBottom: '35.25%', width: '560px', maxWidth: '80%', overflow: 'hidden' }}>
          <iframe 
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} 
            src="https://www.youtube.com/embed/wl734JTCaIQ" 
            frameBorder="0" 
            allowFullScreen="true">
          </iframe>
        </div>

        <hr style={{width: '80%', margin: '0 auto', marginTop: 20, marginBottom: 20}}/>

        <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 24, textAlign: 'center', width: '80%', margin: '0 auto'}}>
          {'Features:\n\n*Use [Alt-B] to get an instant transcript and translation of any audio playing on your computer\n\n*Use [Alt+C] to capture any text on your screen, including in images, subtitles, or video'}
        </Text>

        <video width="70%" autoPlay loop muted
              src="./assets/WhatThat_TextCapture_v1.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        
        <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 24, textAlign: 'center', width: '80%', margin: '0 auto'}}>          
          {'*Save vocabulary and audio clips and review with spaced-repitition flashcards\n\n*Get instant example sentences for any word or phrase'}
        </Text>

        <hr style={{width: '80%', margin: '0 auto', marginTop: 20, marginBottom: 20}}/>

        <Text style={{fontFamily: 'SourceSansPro_300Light', fontSize: 24, textAlign: 'center', width: '80%', margin: '0 auto'}}>
          {'Upcoming:\n\n*Continued improvements for viewing, saving, and editing flashcards\n\n*Refinements to the transcription and description algorithms\n\n*Export tools to text, csv, and Anki'}
        </Text>

        <hr style={{width: '80%', margin: '0 auto', marginTop: 20}}/>

        <Text style={{fontFamily: 'SourceSansPro_300Light', fontSize: 18, textAlign: 'center', width: '80%'}}>
          {'\nThat\'s right - free instant transcription and translation of audio coming from YouTube, \nSpotify, \nNetflix, \niTalki, \nGoogle Meets, or \nliterally any sound playing on your computer.\n'}
        </Text>
        <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 18, textAlign: 'center', width: '80%'}}>
          {'\nHow does it work? Start it running in the background, then when audio is playing, press Alt+B to see a transcript and translation of the last 10 seconds of audio that just played.\n'}
        </Text>
        <Text style={{fontFamily: 'SourceSansPro_300Light', fontSize: 18, textAlign: 'center', width: '80%'}}>
          {'\nIt\'s perfect for watching shows, videos, or listening to podcasts in a language that you\'re learning!'}
        </Text>

        <hr style={{width: '80%', margin: '0 auto', marginTop: 20, marginBottom: 20}}/>
        <Text style={{fontFamily: 'SourceSansPro_300Light', fontSize: 18, textAlign: 'center', width: '80%'}}>
          {'Current languages:\nEnglish\nSpanish\nGerman\nFrench\nItalian\nPortuguese\nRussian\nJapanese\nKorean\nMandarin\n\nLet me know if you have a language request!'}
        </Text>

        <hr style={{width: '80%', margin: '0 auto', marginTop: 20, marginBottom: 20}}/>

        <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 18, textAlign: 'center', width: '80%'}}>
            {'\nClick here to '}
          <Text onPress={handleGetTokenPress} style={{color: 'blue', textDecorationLine: 'underline'}}>
            get a token for free
          </Text>
          {'! You will receive an email with a token to input when you launch WhatThat.'}
        </Text>

        <Text style={{fontFamily: 'SourceSansPro_300Light', fontSize: 18, textAlign: 'center', width: '80%'}}>
        {"\nWhatThat is free to use! You can do up to 50 transcriptions and translations every 3 days, free of charge.\n\nJust let me know if you run into any bugs or issues so I can fix them please!"}
        </Text>

        <hr style={{width: '80%', margin: '0 auto', marginTop: 20, marginBottom: 20}}/>

        <Text ref={downloadRef} style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 26, textAlign: 'center', width: '75%', margin: 10}}>{'Installation Instructions:'}</Text>


        <Text style={{ fontFamily: 'SourceSansPro_300Light', fontSize: 18, textAlign: 'center', width: '80%' }}>
          {'1. '}
          <Text onPress={handleGetTokenPress} style={{color: 'blue', textDecorationLine: 'underline'}}>
            Get a token here.
          </Text>
          {'\n\n2. Download, install, and run WhatThat - add your token when prompted.\n\n3. Start enjoying content in your target language\n\n4. Press Alt+B whenever you don\'t quite catch something that is said\n\n5. Learn and enjoy :)\n\n'}
        </Text> 

        <TouchableOpacity style={{fontFamily: 'SourceSansPro_600SemiBold', color: 'blue', fontSize: 18, textAlign: 'center', width: '80%', textDecorationLine: 'underline'}} onPress={() => Linking.openURL('https://storage.googleapis.com/what-that-host-bucket/WhatThatInstaller_v020.exe')}>
            {"\n\nClick here to download for Windows! You may see a warning because the tool is new - please click \"More Info\", then \"Run Anyways\" to install it."}
        </TouchableOpacity>
    

        <Text style={{fontFamily: 'SourceSansPro_300Light', fontSize: 18, textAlign: 'center', width: '70%'}}>
        {'\n\nYou can contact me at bricee98@gmail.com. Email me if you want to make a suggestion, report a bug, or just say hi.\n\nPlease note that this project is no longer actively maintained, but you can still email me if you want to use the tool.'}
        </Text>

      </View>

    );


}

function TokenPage() {

  const [email, setEmail] = useState('');
  const [tokenSent, setTokenSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleGetTokenPress = () => {
    window.location.href = '/getToken'
  };

  const handleHomePressed = () => {
    window.location.href = '/'
  }

  const handleAboutPressed = () => {
    window.location.href = '/about'
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const generateToken = async () => {
    try {
      if (!validateEmail(email)) {
        setErrorMessage('Invalid email address');
        return;
      }
      setErrorMessage(null);
      const token = (Math.random().toString(36) + '00000000000000000').slice(2, 10);

      const tokenExist = await getDocs(query(collection(db, 'tokens'), where('token', '==', token)));
      const emailExist = await getDocs(query(collection(db, 'tokens'), where('email', '==', email)));
  
      if (!tokenExist.empty || !emailExist.empty) {
        setErrorMessage('Token or email already exists');
        return;
      }
  
      await setDoc(doc(db, 'tokens', token), {
        token,
        email,
        paid: false,
        remainingUses: 100,
      });
  
      emailjs.init(Constants.manifest.extra.emailjsPublicKey);
      emailjs
        .send('service_9xukfus', 'template_yn5qz8d', {
          token,
          to_email: email,
        })
        .then(
          (response) => {
            console.log('SUCCESS!', response.status, response.text);
            setTokenSent(true);  // Set tokenSent to true upon successful email sending
          },
          (error) => {
            console.error('FAILED...', error);
          }
        );
    } catch (error) {
      console.error('Error generating token:', error);
    }
  }


  return(
  <View style={styles.boxcontainer}>

    <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 42, textAlign: 'center', width: '75%', margin: 10}} onPress={() => {window.location.href = '/'}}>{'WhatThat'}</Text>
    <View style={styles.headercontainer}>

          <Button 
              title={'Home'}
              onPress={() => {
                handleHomePressed();
              }}
              containerStyle={{width: '28%', minWidth: 60, marginTop: 0, marginBottom: 15}}
              titleSize={18}
            />
          <Button 
              title={'About'}
              onPress={() => {
                handleAboutPressed();
              }}
              containerStyle={{width: '28%', minWidth: 60, marginTop: 0, marginBottom: 15}}
              titleSize={18}
            />
          <Button 
              title={'Token'}
              onPress={() => {
                handleGetTokenPress();
              }}
              containerStyle={{width: '28%', minWidth: 60, marginTop: 0, marginBottom: 15}}
              titleSize={18}
            />

        </View>
    <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 24, textAlign: 'center', width: '80%'}}>
    {'Request a Token\n'}
    </Text>

    <Text style={[styles.headerText, {fontFamily: 'SourceSansPro_600SemiBold', marginTop: 20, textAlign: 'center', width: '75%'}]}>{'Enter your email below to recieve a token that you can use with WhatThat for free. If you don\'t recieve your token, you can contact me at bricee98@gmail.com.'}</Text>
    
    {errorMessage && (
      <Text style={{ color: 'red', fontFamily: 'SourceSansPro_600SemiBold', fontSize: 28, textAlign: 'center', marginBottom: 10 }}>{errorMessage}</Text>
    )}

    <InputField
      inputStyle={{
        fontSize: 14
      }}
      containerStyle={{
        backgroundColor: '#fff',
        marginTop: 15,
        marginBottom: 20,
        width: '60%'
      }}
      leftIcon='email'
      placeholder='Enter email'
      autoCapitalize='none'
      keyboardType='email-address'
      textContentType='emailAddress'
      autoFocus={true}
      value={email}
      onChangeText={text => setEmail(text)}
    />

  <View style={{borderColor: 'blue', borderWidth: 0, width: '50%', alignItems: 'center', justifyContent: 'center'}}>
    {tokenSent ? (
      <Text style={{ fontSize: 14, color: 'green', marginBottom: 45 }}>Token has been sent successfully!</Text>
    ) : (
      <Button 
        title={'Generate Token'}
        onPress={() => {
          generateToken();
        }}
        containerStyle={{width: '28%', minWidth: 60, marginTop: 0, marginBottom: 45}}
        titleSize={14}
      />
    )}
  </View>
        

  </View>);
}

function AboutPage() {

  const handleGetTokenPress = () => {
    window.location.href = '/getToken'
  };

  const handleHomePressed = () => {
    window.location.href = '/'
  }

  const handleAboutPressed = () => {
    window.location.href = '/about'
  }

  return(
      <View style={styles.boxcontainer}>


        <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 42, textAlign: 'center', width: '75%', margin: 10}}>{'WhatThat'}</Text>
        <View style={styles.headercontainer}>

          <Button 
              title={'Home'}
              onPress={() => {
                handleHomePressed();
              }}
              containerStyle={{width: '28%', minWidth: 60, marginTop: 0, marginBottom: 15}}
              titleSize={18}
            />
          <Button 
              title={'About'}
              onPress={() => {
                handleAboutPressed();
              }}
              containerStyle={{width: '28%', minWidth: 60, marginTop: 0, marginBottom: 15}}
              titleSize={18}
            />
          <Button 
              title={'Get Token'}
              onPress={() => {
                handleGetTokenPress();
              }}
              containerStyle={{width: '28%', minWidth: 60, marginTop: 0, marginBottom: 15}}
              titleSize={18}
            />

          </View>

        <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 24, textAlign: 'center', width: '80%'}}>
        {'About\n'}
        </Text>

        <Text style={{fontFamily: 'SourceSansPro_300Light', fontSize: 18, textAlign: 'center', width: '80%'}}>
        {"WhatThat is a side project I made to help level-up my Spanish listening skills. It's been super useful to me and those that I've been able to share it with, so I hope you'll like it too. Please let me know if you have any suggestions or if you run into any issues - feel free to contact me at bricee98@gmail.com.\n\nPlease note that this project is no longer actively maintained, but you can still email me if you want to use the tool.\n\n"}
        </Text>

        <Text style={{fontFamily: 'SourceSansPro_600SemiBold', fontSize: 24, textAlign: 'center', width: '80%'}}>
        {'Privacy Policy\n'}
        </Text>

        <Text style={{fontFamily: 'SourceSansPro_300Light', fontSize: 18, textAlign: 'center', width: '80%'}}>
        {"None of the audio that you upload is retained by the servers. I do store the transcriptions and translations for a short while for troubleshooting purposes, but they are deleted every week.\n\n"}
        </Text>

      </View>

    );


}
        
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundimage:{
    flex: 1,
    zIndex: -10,
    position: 'fixed',
    width: '100%',
    minWidth: ' 100%',
    height: '100%',
    minHeight: '100%',
    resizeMode: 'cover'
  },
  boxcontainer: {
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#BBBBBB',
    padding: 10,
    marginTop: 10,
    width: '90%',
    backgroundColor: '#FFFFFF',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.32,
    shadowRadius: 5.46,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 75
  },
  headercontainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 85,
    width: '80%'
  },
});
